var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.loading_inicial_data)?_c('div',[_c('custom-breadcrumb',{attrs:{"pageTitle":_vm.$t('Raices'),"breadcrumb":[],"helpLink":{ name: 'faq-question', params:{
       category: 'Raices',
       slug:'raices-integration-user-manual',
       id: 24
     } }}}),(!_vm.process_finished && !_vm.process_not_allowed && _vm.school_id)?_c('form-wizard',{staticClass:"mb-3",attrs:{"color":"#87bced","title":null,"subtitle":null,"shape":"square","no-button":"","finish-button-text":_vm.$t('form_wizard.finish_button'),"back-button-text":_vm.$t('form_wizard.back_button'),"next-button-text":_vm.$t('form_wizard.next_button')},on:{"on-complete":_vm.formSubmitted}},[_c('tab-content',{attrs:{"title":_vm.$t('raices.upload_documents'),"before-change":_vm.validationOne}},[(_vm.isInitial || _vm.isSaving)?_c('form',{attrs:{"enctype":"multipart/form-data","novalidate":""}},[_c('b-row',[_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12"}},[_c('h5',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.$t('raices.upload_documents'))+" ")]),_c('small',{staticClass:"text-muted"},[_vm._v(" "+_vm._s(_vm.$t('raices.select_documents'))+" ")])]),_c('b-col',{attrs:{"md":"6"}},[_c('div',{class:[_vm.show_raices_error ?'dropbox-hasnt-file':_vm.isSaving ?'dropbox-is-saving':_vm.doc_raices ?'dropbox-has-file' : 'dropbox' ]},[_c('input',{staticClass:"input-file",attrs:{"type":"file","name":"raicesFile","disabled":_vm.isSaving,"accept":"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"},on:{"change":function($event){_vm.filesChange($event.target.name, $event.target.files); _vm.fileCount = $event.target.files.length}}}),(_vm.isInitial && !_vm.doc_raices)?_c('p',{domProps:{"innerHTML":_vm._s(
                     _vm.$t('raices.raices_description', { doc_type: 'Raices' })
                   )}}):_vm._e(),(_vm.isSaving)?_c('p',[_vm._v(" "+_vm._s(_vm.$t('raices.uploading_document'))+"... "),_c('b-spinner',{attrs:{"variant":"primary","label":"Text Centered"}})],1):(_vm.doc_raices)?_c('p',[_vm._v(" "+_vm._s(_vm.$t('raices.selected_document'))+" "),_c('br'),_c('strong',[_vm._v(_vm._s(_vm.doc_raices))])]):_vm._e()])]),_c('b-col',{attrs:{"md":"6"}},[_c('div',{class:[_vm.show_educa_error ?'dropbox-hasnt-file':_vm.isSaving ?'dropbox-is-saving':_vm.doc_educamadrid ?'dropbox-has-file' : 'dropbox' ]},[_c('input',{staticClass:"input-file",attrs:{"type":"file","name":"educaFile","disabled":_vm.isSaving},on:{"change":function($event){_vm.filesChange($event.target.name, $event.target.files); _vm.fileCount = $event.target.files.length}}}),(_vm.isInitial && !_vm.doc_educamadrid)?_c('p',{domProps:{"innerHTML":_vm._s(
                     _vm.$t('raices.raices_description', { doc_type: 'EducaMadrid' })
                   )}}):_vm._e(),(_vm.isSaving)?_c('p',[_vm._v(" "+_vm._s(_vm.$t('raices.uploading_document'))+"... "),_c('b-spinner',{attrs:{"variant":"primary","label":"Text Centered"}})],1):(_vm.doc_educamadrid)?_c('p',[_vm._v(" "+_vm._s(_vm.$t('raices.selected_document'))+" "),_c('br'),_c('strong',[_vm._v(_vm._s(_vm.doc_educamadrid))])]):_vm._e()])])],1)],1):_vm._e()]),_c('tab-content',{attrs:{"title":_vm.$t('raices.validate_information'),"before-change":_vm.validationFormInfo}},[_c('validation-observer',{ref:"infoRules",attrs:{"tag":"form"}},[_c('b-row',[_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12"}},[_c('h5',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.$t('raices.validate_information'))+" ")]),_c('small',{staticClass:"text-muted"},[_vm._v(_vm._s(_vm.$t('raices.validate_information_description')))])]),_c('b-col',{attrs:{"cols":"12"}},[_c('raices-data-table',{attrs:{"raices_data":_vm.raices_data_unido,"loading_data":_vm.loading_data}})],1),(!_vm.loading_data && _vm.raices_data_unido.length > 0)?_c('b-col',{attrs:{"cols":"12"}},[_c('b-alert',{staticStyle:{"font-weight":"400"},attrs:{"variant":"primary","show":""}},[_c('h4',{staticClass:"alert-heading"},[_vm._v(" Por favor, verifica la información. ")]),_c('div',{staticClass:"alert-body"},[_c('p',[_vm._v("Si la información que se muestra en la verificación "),_c('b',[_vm._v("no es correcta")]),_vm._v(", puedes "),_c('span',{staticClass:"go-back-button",on:{"click":function($event){return _vm.refresh()}}},[_vm._v("volver al paso anterior")]),_vm._v(" y cargar de nuevo los ficheros.")]),_c('p',[_vm._v("Si todos los datos son correctos y deseas continuar, debes marcar la casilla de confirmación y luego pulsar en el botón \"Continuar\".")]),_c('br'),_c('b-form-group',{attrs:{"label":"","label-for":"confirmación"}},[_c('validation-provider',{attrs:{"name":"confirmación","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                   var errors = ref.errors;
return [_c('b-form-checkbox',{attrs:{"id":"confirmación","disabled":_vm.validacion_selected,"state":errors.length > 0 ? false:null},model:{value:(_vm.validacion_selected),callback:function ($$v) {_vm.validacion_selected=$$v},expression:"validacion_selected"}},[_vm._v(" "+_vm._s(_vm.$t('raices.confirm_information'))+" ")]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2351494224)})],1)],1)])],1):_vm._e()],1)],1)],1),_c('tab-content',{attrs:{"title":_vm.$t('raices.end_process')}},[_c('b-alert',{attrs:{"variant":"secondary","show":""}},[_c('h4',{staticClass:"alert-heading"},[_vm._v(" "+_vm._s(_vm.$t('raices.end_process'))+" ")]),_c('div',{staticClass:"alert-body"},[_c('p',{domProps:{"innerHTML":_vm._s(_vm.$t('raices.end_process_description', {user_email:_vm.user_email}))}})])])],1)],1):_vm._e(),(_vm.process_not_allowed)?_c('b-alert',{attrs:{"variant":"warning","show":""}},[_c('h4',{staticClass:"alert-heading"},[_vm._v(" "+_vm._s(_vm.$t('raices.process_not_allowed'))+" ")]),_c('div',{staticClass:"alert-body"},[_c('p',[_vm._v(_vm._s(_vm.$t('raices.process_not_allowed_description')))])])]):_vm._e()],1):_c('div',{staticClass:"text-center mt-2 mb-2"},[_c('b-spinner',{attrs:{"variant":"primary","label":"Text Centered"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }